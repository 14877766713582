<template>
  <div>
    <NCard
      :style="{ visibility: pageInited ? 'visible' : 'hidden' }"
      size="small"
      title="任务教材建设"
      :segmented="{ content: 'hard' }"
    >
      <ImportExportArchitecture
        style="margin-bottom: 12px;"
        :dirArr="taskArr"
        @import-success="handleImportSuccess"
      />
      <NGrid :x-gap="15" :cols="24">
        <NGi :span="8">
          <MaterialDir
            ref="dirRef"
            searchContentName="任务/子任务"
            :generateLv1ItemFn="() => generateTaskItem(true)"
            :generateLv2ItemFn="() => generateSubTaskItem(true)"

            :dirData="taskArr"
            @editing-section-change="handleSelectTask"
          />
        </NGi>
        <NGi :span="16">
          <div>
            <div v-if="selectedTask">
              <TaskContent
                :addedKnowledgeIdArr="addedKnowledgeIdArr"
                :taskData="selectedTask"
                :skillArr="previewOtherData.all_skill"
                :idSkillObjMap="previewOtherData.idSkillObjMap"
              />
            </div>
            <div v-else style="padding: 90px 0; border: 1px solid #eee;">
              <NEmpty description="选择一个子任务开始">
                <template #icon>
                  <NIcon>
                    <NotStartedOutlined />
                  </NIcon>
                </template>
              </NEmpty>
            </div>
          </div>
        </NGi>
      </NGrid>
      <template #footer>
        <NSpace size="small">
          <RouterLink :to="`list?type=${materialTypeValMap.TASK}`" style="margin-right: 10px; text-decoration: none;">
            <NButton size="large" style="width: 85px;">返回</NButton>
          </RouterLink>
          <NButton
            size="large"
            style="width: 84px;"
            type="primary"
            ghost
            tag="a"
            target="_blank"
            href="/full-page/task-material-preview"
            @click="handlePreview"
          >预览教材</NButton>
          <NButton size="large" style="width: 120px;" type="primary" @click="handleDone">完成</NButton>
        </NSpace>
      </template>
    </NCard>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, reactive, computed, nextTick } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { NCard, NSpace, NButton, NIcon, NGrid, NGi, NEmpty, useMessage } from 'naive-ui';
  import { NotStartedOutlined } from '@vicons/material';

  import PageLoading from '@/components/PageLoading/index.vue';
  import ImportExportArchitecture from './components/ImportExportArchitecture.vue';
  import MaterialDir from '@/views/material/index/build/components/MaterialDir.vue';
  import TaskContent from './components/TaskContent.vue';

  import { resStatusEnum } from '@/enumerators/http.js';
  import {
    generateSubTaskItem,
    generateTaskItem
  } from './generate-data.js';
  import { generateReqData } from './req-data.js';
  import { materialTypeValMap } from '@/enums/material-type.js'

  import {
    getMaterialDetail,
    editMaterial
  } from '@/api/material.js'

  const router = useRouter();
  const route = useRoute();
  const message = useMessage();
  const { SUCCESS } = resStatusEnum;
  const loading = ref(false);

  const pageInited = ref(false);
  const materialID = route.query.id;

  const dirRef = ref(null);

  const taskArr = ref([]);
  const addedKnowledgeIdArr = computed(() => {
    const arr = [];
    taskArr.value.forEach(({ section_list }) => {
      section_list.forEach(({ knowledgeArr }) => {
        arr.push(...knowledgeArr.map(({ id }) => id))
      });
    });
    return arr;
  });
  function initData() {
    if (!materialID) {
      return;
    }
    loading.value = true;
    getMaterialDetail({ 'Material[id]': materialID }).then(({ code, data }) => {
      if (code === SUCCESS && data) {
        pageInited.value = true;
        taskArr.value.splice(0);
        const { chapter, title, introduce, all_skill } = data;
        if (Array.isArray(chapter) && chapter.length > 0) {
          taskArr.value = chapter.map(item => generateTaskItem(false, item));
        } else {
          taskArr.value.push(generateTaskItem(true));
        }
        nextTick(() => {
          dirRef.value.updateDirDataItemTags();
          dirRef.value.expandAllChapters();
        });

        const idSkillObjMap = {};
        if (Array.isArray(all_skill)) {
          all_skill.forEach(item => {
            idSkillObjMap[item.id] = item;
          });
        }
        Object.assign(previewOtherData, { title, introduce, all_skill: Array.isArray(all_skill) ? all_skill : [], idSkillObjMap });
      }
    }).finally(() => {
      loading.value = false;
    });
  }
  initData();

  function handleImportSuccess(arr) {
    taskArr.value.splice(0);
    if (arr) {
      taskArr.value.push(
        ...arr.map(item => generateTaskItem(false, item))
      );
    }
    dirRef.value.updateDirDataItemTags();
    dirRef.value.expandAllChapters();
  }

  const selectedTask = ref(null);
  function handleSelectTask({ chapterSectionType, data }) {
    selectedTask.value = chapterSectionType === 'section' ? data : null;
  }

  const previewOtherData = reactive({
    title: '',
    introduce: '',
    all_skill: [],
    idSkillObjMap: {}
  });
  function handlePreview() {
    window.localStorage.setItem('a-task-material-preview', JSON.stringify({
      ...previewOtherData,
      taskArr: taskArr.value
    }));
  }
  function handleDone() {
    loading.value = true;
    editMaterial(generateReqData(materialID, taskArr.value)).then(({ code }) => {
      if (code === SUCCESS) {
        message.success('保存成功');
        router.push(`list?type=${materialTypeValMap.TASK}`);
      }
    }).finally(() => {
      loading.value = false;
    });
  }
</script>