import { resourceTypeValMap } from './generate-data.js';

function generateReqSubTaskData({ title, teachingArr, knowledgeArr }) {
  const obj = {
    section_title: title,
    model: [
      {
        title: '任务内容',
        data: []
      }
    ]
  };
  const data = obj.model[0].data;
  if (teachingArr.length > 0) {
    teachingArr.forEach(({ id }) => {
      data.push({
        type: resourceTypeValMap.UPLOAD_FILE,
        list: id
      });
    });
  }
  if (knowledgeArr.length > 0) {
    knowledgeArr.forEach(({ id, name }) => {
      data.push({
        type: resourceTypeValMap.KNOWLEDGE,
        list: id,
        base_name: name
      });
    });
  }
  return obj;
}

function generateReqTaskData({ title, section_list }) {
  const obj = {
    title,
    section: section_list.map(item => generateReqSubTaskData(item))
  };
  return obj;
}

export function generateReqData(materialID, taskArr) {
  return {
    'Material[id]': materialID,
    'Material[type]': 2,
    'Material[chapter]': taskArr.map(item => generateReqTaskData(item))
  }
}