<template>
  <div>
    <NButton @click="handleClickImport">导入架构</NButton>
    <NButton
      style="margin-left: 10px;"
      type="primary"
      @click="handleClickExport"
    >导出架构</NButton>

    <ImportDirModal
      ref="importRef"
      taskMaterial
      importContentName="任务"
      @import-success="emit('import-success', $event)"
    />
    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { NButton, useMessage } from 'naive-ui';

  import PageLoading from '@/components/PageLoading/index.vue';
  import ImportDirModal from '@/views/material/index/build/components/ImportDirModal.vue';

  import { chapterSection } from '@/views/material/index/build/enum/edit-type-map.js';

  import { exportDir } from '@/api/material.js';

  const emit = defineEmits(['import-success']);
  const props = defineProps({
    dirArr: {
      type: Array,
      default: () => []
    }
  });

  const message = useMessage();
  const loading = ref(false);

  const importRef = ref(null);
  const handleClickImport = () => {
    importRef.value.openModal();
  };
  function handleClickExport() {
    loading.value = true;
    const chapters = [];
    props.dirArr.forEach(({ title, section_list }) => {
      chapters.push({
        title,
        type: chapterSection.CHAPTER
      });
      if (Array.isArray(section_list)) {
        section_list.forEach(({ title }) => {
          chapters.push({
            title,
            type: chapterSection.SECTION
          });
        });
      };
    });
    exportDir({
      type: 1,
      Material: chapters
    }).then(res => {
      saveAs(new Blob([res], {
        type: 'application/octet-stream'
      }), `任务架构.xlsx`);
    }).catch(err => {}).finally(() => {
      loading.value = false;
    });
  };
</script>