<template>
  <div>
    <NModal
      v-model:show="modalShow"
      preset="card"
      size="small"
      :segmented="{ content: 'hard', footer: 'hard' }"
      title="导入架构"
      style="width: 600px;"
      :mask-closable="false"
      @after-leave="clearModal"
    >
      <template #default>
        <NForm
          ref="formRef"
          label-placement="left"
          :label-width="85"
          require-mark-placement="left"
          style="padding: 20px 30px 0 0;"
          :model="formValue"
          :rules="formRules"
        >
          <NFormItem label="模板：" path="file">
            <div>
              <NSpace>
                <NButton
                  :disabled="!!formValue.file"
                  @click="handleClickUpload"
                >上传文件</NButton>
                <NButton
                  @click="handleClickTemplate"
                >模板下载</NButton>
              </NSpace>
              <div style="margin-top: -30px; overflow-y: hidden;">
                <NUpload
                  ref="uploadRef"
                  :default-upload="false"
                  :max="1"
                  :accept="permitFileExtsWithDot.join(',')"
                  @before-upload="beforeUpload"
                  @change="handleChange"
                />
              </div>
            </div>
          </NFormItem>
        </NForm>
      </template>
      <template #footer>
        <NSpace justify="end">
          <NButton @click="closeModal">取消</NButton>
          <NButton type="primary" @click="handleConfirm">确定</NButton>
        </NSpace>
      </template>
    </NModal>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, reactive } from 'vue';
  import { useMessage, useDialog } from 'naive-ui';
  import { saveAs } from 'file-saver'

  import PageLoading from '@/components/PageLoading/index.vue';

  import { importDir, exportDir } from '@/api/material.js';

  import { resourceEnum } from '@/enumerators/resource-types-map.js';
  import themeSettings from '@/settings/theme-settings.js';
  import env from '@/settings/env.js';
  import { getExtWithDotFromFileName } from '@/utils/utils.js';
  import { resStatusEnum } from '@/enumerators/http.js';
  import { chapterSection } from '../enum/edit-type-map.js';

  const { SUCCESS } = resStatusEnum;
  const message = useMessage();
  const dialog = useDialog();
  const loading = ref(false);
  const emit = defineEmits(['import-success']);
  const props = defineProps({
    importContentName: {
      type: String,
      default: '章节'
    },
    taskMaterial: {
      type: Boolean,
      default: false
    }
  });

  const resReqMap = {
    file: {
      reqKey: 'Material[file]',
      default: null,
      rule: {
        key: 'file',
        required: true,
        validator: (rule, value) => {
          if (!!value) {
            return true;
          } else {
            return new Error('请选择文件');
          }
        }
      }
    }
  };

  const modalShow = ref(false);
  const openModal = () => {
    modalShow.value = true;
  };
  const closeModal = () => {
    modalShow.value = false;
  };
  const clearModal = () => {
    Object.keys(resReqMap).forEach(key => {
      formValue[key] = resReqMap[key].default;
    });
  };
  const handleConfirm = () => {
    formRef.value.validate(errors => {
      if (!errors) {
        dialog.warning({
          title: '架构导入确认',
          content: `导入${props.importContentName}架构后，原编辑内容将会被清空，是否继续？`,
          positiveText: '继续导入',
          negativeText: '取消',
          onPositiveClick: () => {
            loading.value = true;
            const reqData = {};
            if (props.taskMaterial) {
              reqData.type = 1;
            }
            Object.keys(resReqMap).forEach(key => {
              switch (key) {
                case 'file':
                  reqData[resReqMap[key].reqKey] = formValue[key].file;
                  break;
                default:
                  reqData[resReqMap[key].reqKey] = formValue[key];
              }
            });
            importDir(reqData).then(res => {
              if (res.code === SUCCESS) {
                closeModal();
                message.success('导入成功');
                if (Array.isArray(res.data)) {
                  const chapters = [];
                  res.data.forEach(({ title, type }) => {
                    switch (Number(type)) {
                      case chapterSection.CHAPTER:
                        chapters.push({
                          title
                        });
                        break;
                      case chapterSection.SECTION:
                        const lastChapterItem = chapters[chapters.length - 1];
                        if (lastChapterItem) {
                          lastChapterItem.section_list || (lastChapterItem.section_list = []);
                          lastChapterItem.section_list.push({
                            title
                          });
                        }
                        break;
                    }
                  });
                  emit('import-success', chapters);
                } else {
                  emit('import-success', null);
                }
              } else {
                // message.error('导入失败');
              }
            }).catch(err => {}).finally(() => {
              loading.value = false;
            });
          },
          onNegativeClick: () => {}
        });
      }
    });
  };

  const formRef = ref(null);
  const formValue = (() => {
    const tempValue = {};
    Object.keys(resReqMap).map(key => {
      tempValue[key] = resReqMap[key].default;
    });
    return reactive(tempValue);
  })();
  const formRules = (() => {
    const tempRules = {};
    Object.keys(resReqMap).map(key => {
      const rule = resReqMap[key].rule;
      rule && (tempRules[key] = rule);
    });
    return tempRules;
  })();
  
  const triggerFileValidator = () => {
    formRef.value.validate(
      errors => {},
      rule => rule.key === 'file'
    );
  };

  const permitFileExtsWithDot = ['.xlsx', '.xls'];
  const uploadRef = ref(null);
  const handleClickUpload = () => {
    uploadRef.value.openOpenFileDialog();
  };
  const beforeUpload = ({ file }) => {
    const {
      name
    } = file.file;
    const extWithDot = getExtWithDotFromFileName(name);
    if (permitFileExtsWithDot.includes(extWithDot)) {
      return true;
    } else {
      message.error(`请选择 ${ permitFileExtsWithDot.join('、') } 格式文件`);
      return false;
    }
  };
  const handleChange = ({ fileList }) => {
    formValue.file = fileList[0];
    triggerFileValidator();
  };

  const handleClickTemplate = () => {
    loading.value = true;
    const reqData = {};
    if (props.taskMaterial) {
      reqData.type = 1;
    }
    exportDir(reqData).then(res => {
      saveAs(new Blob([res], {
        type: 'application/octet-stream'
      }), `${props.importContentName}架构模板.xlsx`);
    }).catch(err => {}).finally(() => {
      loading.value = false;
    });
  };

  defineExpose({
    openModal
  });
</script>